<template>
  <div class="bigbox">
    <div>
      <nav2 />
    </div>
    <div class="box">
      <div class="topbox">
        <div>
          <div style="font-size:16px;padding-top:20px">已支付订单</div>
          <van-divider />
          <div>
            <div>
              <span style="font-size:20pt;color:#FF4500">{{this.carobj.totalFee}}</span>
              元
            </div>
          </div>
          <div style="margin-top:10px">
            已抵扣
            <span style="color:red">{{this.carobj.mileage}}</span> +支付
            <span style="color:red">{{this.carobj.cash}}</span>
          </div>
          <div style="padding-top:20px"></div>
        </div>
      </div>
      <div class="address">
        <div class="input"
             style="line-height: 50px;">
          <span class="yq">11</span>
          <span class="over">{{this.carobj.startAddress}}</span>
        </div>
        <van-divider />
        <div class="input"
             style>
          <span class="yellow">11</span>
          <span class="over">{{this.carobj.endAddress}}</span>
        </div>
      </div>

      <div class="foot">
        <div style="font-size:16px;margin-left:20px;padding-top:15px">{{this.carobj.carType}}出行</div>
        <div class="time">
          <span>完成时间</span>
          <span class="size">{{this.carobj.finishTime}}</span>
        </div>

        <div class="number">
          <span>订单编号</span>
          <span class="size">{{this.carobj.orderNum}}</span>
        </div>
      </div>
      <!-- <div class="startbox" v-show="this.status">
        <div class="title">我的评价</div>
        <div class="flex">
          <van-rate size="14px" v-model="this.detaobj.Score" color="#ffd21e" readonly />
          <div class="size">{{this.detaobj.EvaluationTime}}</div>
        </div>
        <div class="conent">{{this.detaobj.Comment}}</div>
      </div>
      <div class="btnbox">
        <button v-if="this.isshow==false" class="btn1" @click="complaint">我要投诉</button>
        <button  v-else disabled  class="btn1">我要投诉</button>

        <button v-if="this.status==false" @click="evaluate" class="btn2"  >评价本订单</button>
        <button v-else disabled class="btn2" style="color:#aaa">评价本订单</button>
      </div> -->
    </div>
    <div class="go"
         @click="goindex">
      <button class="gobtn">
        <img style="width:15px;height:15px"
             src="../assets/images/index.png"
             alt /> 返回首页
      </button>
    </div>
  </div>
</template>

<script>
import nav2 from '@/components/Nav2.vue'
import { loadorder_api } from '../http/api/price'
import { pjdetails_api, complaindeta_api } from '../http/api/service'
export default {
  name: 'TaxiH5OrderDetails',
  components: {
    nav2,
  },
  data () {
    return {
      detaobj: {},
      carobj: {},
      nowOrder: {},
      status: false,
      id: '',
      carType: '',
      backNum: '',
      complain: '',
      isshow: false
      // iPathOrderId:'',
      // carSource:'',
    }
  },

  mounted () {
    this.nowOrder = JSON.parse(sessionStorage.getItem('nowOrder'))
    this.order()

    //console.log(this.detaobj!={});

    //this.complaindeta()
  },

  methods: {
    //评价详情
    async deta () {
      console.log(this.carobj.carType)
      let res = await pjdetails_api({
        carSource: this.carobj.carType,
        iPathOrderId: this.carobj.backNum,
      })
      console.log(res)
      if (res.code == 200) {
        if (res.data.Score != null) {
          this.detaobj = res.data
          this.detaobj.Score = Number(this.detaobj.Score)
          this.status = true
        }
        console.log(this.detaobj)
      }
    },
    //投诉详情
    async complaindeta () {
      let res = await complaindeta_api({
        carSource: this.carobj.carType,
        iPathOrderId: this.carobj.backNum,
      })
      console.log(res);
      if (res.code == 200) {
        if (res.data.ComplaintOtherDesc != null) {
          this.complain = res.data

          this.isshow = true
        }

      }
    },
    async order () {
      this.id = this.$route.query.orderid

      let res = await loadorder_api({
        orderId: this.id,
      })
      console.log(res)
      if (res.code == 200) {
        this.carobj = res.data
        this.deta()
        this.complaindeta()
        this.carType = this.carobj.carType
        this.backNum = this.carobj.backNum
        if (this.carobj.carType == 4) {
          this.carobj.carType = '曹操'
        }
        if (this.carobj.carType == 2) {
          this.carobj.carType = '易到'
        }
        if (this.carobj.carType == 3) {
          this.carobj.carType = '神州'
        }
        if (this.carobj.carType == 1) {
          this.carobj.carType = '滴滴'
        }
        if (this.carobj.carType == 5) {
          this.carobj.carType = '首汽'
        }
        if (this.carobj.carType == 7) {
          this.carobj.carType = '红旗'
        }
        if (this.carobj.carType == 12) {
          this.carobj.carType = '如祺'
        }
        if (this.carobj.carType == 11) {
          this.carobj.carType = 'T3'
        }
      }
    },
    //投诉
    complaint () {
      // this.$router.push({
      //   path: '/complaint',
      // })
      this.$router.push({ path: '/complaint', query: { orderid: this.backNum, carType: this.carType } });
    },
    //评价
    evaluate () {
      console.log(this.id);
      this.$router.push({ path: '/evaluate', query: { orderid: this.backNum, carType: this.carType } });
    },
    //返回首页
    goindex () {
      this.$router.push({
        path: '/index',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.bigbox {
  width: 100%;
  height: 100vh;
  background: #eee;
  box-shadow: #ccc 0 0 10px;
  display: flex;
  flex-direction: column;

  .box {
    flex: 1;
    /* 溢出处理 */
    overflow-y: scroll;
    .size {
      color: #666;
    }
    .topbox {
      margin-top: 10px;
      height: 130px;
      background: #fff;
      width: 95%;
      margin-left: 2.5%;
      text-align: center;
      border-radius: 15px;
      .van-divider {
        margin-bottom: 5px;
      }
    }
    .address {
      width: 95%;
      height: 100px;
      margin-top: 10px;
      background: #fff;
      margin-left: 2.5%;
      border-radius: 15px;

      .input {
        height: 50%;
        margin-left: 20px;
        width: 300px;
        margin-top: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .van-divider {
        margin: 0px;
      }
      .over {
        // white-space: nowrap;
        //  overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 10px;
        width: 100px;
      }
      .yq {
        width: 15px;
        height: 15px;
        background: #62ce62;
        border-radius: 50%;
        color: #62ce62;
      }
      .yellow {
        width: 15px;
        height: 15px;
        background: #ffb540;
        border-radius: 50%;
        color: #ffb540;
      }
    }
    .foot {
      width: 95%;
      height: 100px;
      margin-top: 10px;
      background: #fff;
      margin-left: 2.5%;
      border-radius: 15px;
      .time {
        display: flex;
        justify-content: space-between;
        margin: 15px 20px;
      }
      .number {
        display: flex;
        justify-content: space-between;
        margin: 15px 20px;
      }
    }
    .startbox {
      width: 95%;
      height: 100px;
      margin-top: 10px;
      background: #fff;
      margin-left: 2.5%;
      border-radius: 15px;
      .flex {
        display: flex;
        justify-content: space-between;
        margin: 0 15px;
        padding-top: 5px;
      }
      .title {
        margin: 0 15px;
        padding-top: 10px;
        font-size: 14px;
      }
      .conent {
        margin: 0 15px;
        padding-top: 10px;
      }
    }
    .btnbox {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      width: 95%;
      margin-left: 2.5%;
      .btn1 {
        width: 45%;
        height: 50px;
        background: #fff;
        font-size: 14px;
        border: 0;
        border-radius: 15px;
      }
      .btn2 {
        width: 45%;
        height: 50px;
        background: #fff;
        color: #4db9f3;
        font-size: 16px;
        border: 0;
        border-radius: 15px;
      }
    }
  }
  .gobtn {
    width: 95%;
    height: 50px;
    margin-left: 2.5%;
    background: #fff;
    border-radius: 15px;
    border: 0;
    margin-bottom: 20px;
  }
}
</style>